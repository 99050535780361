<template>
  <div>
    <script type='application/ld+json' v-text='jsonld'></script>
    <div class="banner">
      <Carousel/>
    </div>
    <div class="container">
      <div class="tizer grid bg">
        <div class="titem grid" v-for="(item, i) in tizer"  :key="i">
          <v-img
            :src="item.img"
            max-width=100
          ></v-img>
          <div class="titem-txt" v-html="item.txt"></div>
        </div>
      </div>
      <div class="services">
        <div 
          class="sitem" 
          v-for="(item, i) in services" 
          :key="i"
          @click="route(item.path)"
        >
          <v-img
            :src="item.img"
            height="100%"
            width="100%"
            gradient="rgba(0,0,0,.7),rgba(0,0,0,.7)"
            class="img"
          ></v-img>
          <div class="txt" v-html="item.txt"></div>
          <div class="txt-desc"><v-icon left color="#f27937">mdi-arrow-right-bold-box</v-icon>подробнее</div>
        </div>
      </div>
      <Description/>
      <div id="address"></div>
      <Address/>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'
import Address from '@/components/Address.vue'
import Description from '@/components/Description.vue'
export default {
  components: {Carousel, Address, Description},
  metaInfo: {
    title: 'ADR. Опасные грузы | водители международники в Витебске',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'ADR. Обучение водителей для перевозки опасных грузов, подготовка водителей международников в Витебске. Опасные грузы, водители международники. Категории «A» «B» «C» «D» «E»',
      }
    ],
    innerHTML: `
      body div.loading {
        z-index: 999;
        background-color: #0f0f0f;
        opacity: 0.9;
      }
    `,
  },
  data: () => ({
    jsonld: {
      "@context": "http://www.schema.org",
      "@type": "LocalBusiness",
      "name": "ADR - ВитАвтоУниверсал",
      "url": "http://by.vit-avto.by/",
      "logo": "http://by.vit-avto.by/img/logo/logoB.png",
      "description": "ADR. Обучение водителей для перевозки опасных грузов, подготовка водителей международников в Витебске. Опасные грузы, водители международники. Категории «A» «B» «C» «D» «E»",
      "image": "http://by.vit-avto.by/img/logo/logoB.png",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "пр-т Фрунзе, 8",
        "addressLocality": "Витебск",
        "addressCountry": "Беларусь"
      },
      "hasMap": "https://goo.gl/maps/Uwb88CPAPiHky3ye6",
      "openingHours": "Mo, Sa, Su 10:00-20:00 Tu, We, Th, Fr 09:00-20:00",
      "telephone": "+375 29 725-86-25"
    },
    tizer: [
      {img: 'img/svg/clock.svg', txt: 'минимальные <br>сроки обучения'},
      {img: 'img/svg/coins.svg', txt: 'гибкая <br>система цен'},
      {img: 'img/svg/hand.svg', txt: 'современная<br>система обучения'}
    ],
    services: [
      {img: 'img/bg/adr_bg.jpg', txt: 'обучение водителей для <br> перевозки опасных грузов', path: '/adr'},
      {img: 'img/bg/truck_bg.jpg', txt: 'Подготовка водителей <br> международников', path: '/truck'},
      {img: 'img/bg/abc_bg.jpg', txt: 'Автошкола «ВитАвтоУниверсал» <br> все категории «A» «B» «C» «D» «E»', path: 'https://vit-avto.by/'}
    ],
  }),
  methods: {
    route(path) {
      if (path == 'https://vit-avto.by/') {
        document.location.href = path
      } else {
        if(this.$route.path !== path) this.$router.push(path)
      }
    },
    scrollTo(id) {
      let block = document.querySelector(`#${id}`)
      block.scrollIntoView({block: "start", behavior: "smooth"})
    }
  },
  mounted() {
    this.$emit('scrollTo', this.scrollTo)
    window.scrollTo({
      top: 0,
      left: 0,
    })
    /* document.querySelector('#micro-meta').innerHTML = this.micro */
  }
}
</script>

<style lang="scss" scoped>
.tizer {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    align-items: center;
    justify-items: center;
    grid-gap: 10px;
    text-align: center;
    padding: 20px 0;
    margin: 25px 0;
    .titem {
      align-items: center;
      justify-items: center;
      .titem-txt {
        text-transform: uppercase;
        margin-top: 15px;
      }
    }
  }
  .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .sitem {
      height: 250px;
      width: 350px;
      position: relative;
      margin: 7px;
      cursor: pointer;
      overflow: hidden;
      &:hover .img {
        transform: scale(1.2);
      }
      &:hover .txt-desc {
        opacity: 1;
      }
      .img {
        transition: all .3s;
      }
      .txt {
        position: absolute;
        left: 25px;
        bottom: 25px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: bold;
      }
      .txt-desc {
        opacity: 0;
        position: absolute;
        color: #f27937;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        transition: all .3s;
        i {
          margin-bottom: 4px;
        }
      }
    }
  }
</style>
