import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    contact: {},
    cost: {},
    street: []
  },
  mutations: {
    mutContact(state, payload) {
      state.contact = {}
      state.contact = payload
    },

    mutCost(state, payload) {
      state.cost = {}
      state.cost = payload
    },

    mutStreet(state, payload) {
      state.street = []
      state.street = payload.street
    }
  },
  actions: {

    async contact({commit}) {
      let response = await axios({
        method: 'GET',
        url: './json/contact.json'
      })
      let result = await response
      commit('mutContact', result.data)
      commit('mutStreet', result.data)
    },

    async cost({commit}) {
      let response = await axios({
        method: 'GET',
        url: './json/cost.json'
      })
      let result = await  response
      commit('mutCost', result.data)
    }

  },
  getters: {
    getContact: state => state.contact,
    getCost: state => state.cost,
    getStreet: state => {
      let arr = []
      let val = 1
      state.street.map(i => {
        arr.push({text: i, value: val})
        val++
      })
      return arr
    }
  },
  modules: {
  }
})
