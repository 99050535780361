<template>
  <div>
    <div class="divider"></div>
    <v-footer
      padless
      absolute
      color="#303036"
      class="footer"
    >
      <v-card
        flat
        tile
        width="100%"
        class="lighten-1 text-center"
        color="#303036"
      >
        <v-card-text>
          <v-btn
            v-for="(item, i) in items"
            :key="i"
            class="mx-4 ico"
            icon
            @click="location(item.name)"
          >
            <v-icon size="24px">
              {{ item.ico }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>ВитАвтоУниверсал</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data: () => ({
    items: [
      {name: 'home', ico: 'mdi-home'},
      {name: 'vk', ico: 'mdi-vk'},
      {name: 'viber', ico: 'mdi-phone-in-talk'},
    ]
  }),
  methods: {
    ...mapGetters(['getContact']),
    scrollTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    location(type) {
      if(type == 'vk') window.open(this.getContact().social.vk)
      if(type == 'viber') {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          // код для мобильных устройств
          window.location.href=(`viber://add?number=${this.getContact().social.viber.replace(/[-\ \(\)\+]/g, '')}`)
        } else {
          // код для обычных устройств
          window.location.href=`viber://chat?number=${this.getContact().social.viber.replace(/[-\ \(\)]/g, '')}`
        }
      }
      if(type == 'home') {
        this.$route.path == '/' ? this.scrollTop() : this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .divider {
    height: 150px;
  }
  .footer {
    .ico {
      color: #878789 !important;
      &:hover {
        color: #ffffff !important;
      }
    }
  }
</style>
