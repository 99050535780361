<template>
  <div class="home-address bg grid">
    <div class="ha-item">
      <v-icon left>mdi-card-account-details</v-icon>Наши адреса:<br>
      <br>
      <v-icon left small>mdi-map-marker-radius</v-icon>{{getContact.city}}<br>
      <span v-for="street in getContact.street" :key="street"><v-icon left small>mdi-circle</v-icon>{{street}}<br></span>
      <br><br>
      <v-icon left>mdi-phone-classic</v-icon>Наши телефоны:<br>
      <br>
      <span v-for="(phone, p) in getContact.phone" :key="p"><v-icon left small>mdi-phone</v-icon><a :href="`tel:${phone}`">{{phone}}</a><br></span>
    </div>
    <div class="ha-item">
      <v-select
        color="#696969"
        dense
        :items="getStreet"
        label="Выберите адрес"
        v-model="value"
        @input="visible != value ? visible = 0 : false"
      ></v-select>
      <v-progress-circular
        class="load"
        v-if="visible == 0"
        indeterminate
        rotate="5"
        size="50"
        width="5"
        color="#f27937"
      ></v-progress-circular>
      <div v-for="(map, m) in getContact.map" :key="m">
        <iframe v-if="value == m+1" @load="visible = m+1" :src="map.src" width="100%" height="250" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import  {mapGetters} from 'vuex'
export default {
  data: () => ({
    value: 1,
    visible: 0,
  }),
  computed: {
    ...mapGetters([
        'getContact', 
        'getStreet'
      ]),
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
  .home-address {
    grid-template-columns: 1fr 2fr;
    grid-gap: 25px;
    padding: 55px 25px;
    margin: 25px 0;
    color: #696969;
    .ha-item {
      a {
        text-decoration: none;
        color: #696969;
      }
      &:nth-child(2) {
        position: relative;
        .load {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .home-address {
      grid-template-columns: 1fr;
    }
  }
</style>
