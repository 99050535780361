<template>
  <div>
    <TopMenu :scrollTo="scrollTo"/>
    <v-main>
      <router-view @scrollTo="scrollTo = $event"/>
    </v-main>
    <Footer/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import TopMenu from '@/components/TopMenu.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {TopMenu, Footer},
  data: () => ({
    scrollTo: Function
  }),
  methods: {
    ...mapActions(['contact', 'cost'])
  },
  async mounted() {
    await this.contact()
    await this.cost()
  }
}
</script>

<style lang="scss" scoped>
  
</style>
