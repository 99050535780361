<template>
  <div>
    <div :class="`top-line grid ${menu.class}`">
      <div class="tl-item">
        <v-img
          src="img/logo/logoB.png"
          class="logo"
          @click="push('/')"
        ></v-img>
      </div>
      <div class="tl-item">
        <v-btn color="#2e2e2e" to="/">главная</v-btn>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#2e2e2e"
              dark
              v-bind="attrs"
              v-on="on"
            >
              услуги
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="push(item.path)"
            >
              <v-list-item-title>{{ item.link }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn color="#2e2e2e" @click="ScrollToPush('address')">контакты</v-btn>
      </div>
    </div>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="#303036"
        >
          <v-toolbar-title>Контакты</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
          <div class="container">
            <Address/>
            <div>
              <v-icon left style="margin-bottom: 2px;">mdi-information</v-icon>
              <strong style="color: #696969;">Свяжитесь с нами и мы ответин на все Ваши вопросы</strong>
            </div>
            <Footer/>
          </div>
      </v-card>
    </v-dialog>

     <div>
      <div class="line-mmenu">
        <a class="btn-menu" @click="show">
          <v-icon v-if="!active" color="#f27937" large>mdi-menu</v-icon>
          <v-icon v-else color="#f27937" large>mdi-close-box</v-icon>
          <span>Меню</span>
        </a>
      </div>
      <div class="top-menu-block grid">
        <ul>
          <li @click="push('/')">Главная</li>
          <li>
            <ul>
              <li v-for="(item, i) in items" :key="i" @click="push(item.path)">{{item.link}}</li>
            </ul>
          </li>
          <li @click="ScrollToPush('address')">Контакты</li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import Address from '@/components/Address.vue'
import Footer from '@/components/Footer.vue'
export default {
  props: ['scrollTo'],
  components: {Address, Footer},
  data: () => ({
    active: false,
    dialog: false,
    items: [
      {link: 'опасные грузы', path: '/adr'},
      {link: 'международники', path: '/truck'},
      {link: 'автошкола', path: 'https://vit-avto.by/'}
    ],
    menu: {
      class: 'menu-fixed'
    }
  }),
  methods: {
    push(path) {
      if (path == 'https://vit-avto.by/') {
        document.location.href = path
        this.active == true ? this.show() : false
      } else {
        this.$route.path !== path ? this.$router.push(path) : false
        this.active == true ? this.show() : false
      }
    },
    ScrollToPush(id) {
      if(this.$route.path == '/') {
        this.scrollTo(id)
        this.active == true ? this.show() : false
      } else {
        this.dialog = true
        this.active == true ? this.show() : false
      }
    },
    show() {
      this.active = !this.active
      document.body.classList.toggle('m-active')
    }
  },
  mounted() {
    let position = 0
    window.onscroll = () => {
      let current_position = window.pageYOffset
      if (current_position < position) {
        if (this.menu.class != 'menu-fixed') {
          this.menu.class = 'menu-fixed'
        }
      }
      else {
        if (this.menu.class != 'menu-noFixed') {
          this.menu.class = 'menu-noFixed'
        }
      }
      position = current_position
    }
  }
}
</script>

<style lang="scss" scoped>
  .top-line {
    position: fixed;
    left: 0;
    width: 100%;
		z-index: 15;
    height: 50px;
    background: linear-gradient(rgba(23,23,23,.9), rgba(23,23,23,.9));
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    .logo {
      max-width: 190px;
    }
    .tl-item:nth-child(1) {
      .v-image {
        cursor: pointer;
      }
    }
    .tl-item:nth-child(2) {
      a {
        height: 50px;
        border-radius: 0;
        color: #ffffff;
      }
      button {
        height: 50px;
        border-radius: 0;
        color: #ffffff;
      }
    }
  }
  .v-list {
    background-color: #2e2e2e !important;
  }
  .v-list-item__title {
    color: #ffffff;
  }
  .v-menu__content {
    border-radius: 0px;
    border-top: 3px solid rgba(255,255,255,.3);
  }
  .v-list-item {
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
      background: #f27937;
    }
    .v-list-item__title {
      font-size: 0.85rem;
    }
  }
  .v-btn--contained {
    box-shadow: none;
  }
  .menu-fixed {
		top: 0;
    opacity: 1;
		animation-name: menu_show;
		animation-duration: .3s;
	}
	@keyframes menu_show {
		0% {
			opacity: 0;
			top: -50px;
		}
		100% {
			opacity: 1;
			top: 0;
		}
  }
  .menu-noFixed {
		top: -50px;
    opacity: 0;
		animation-name: menu_hide;
		animation-duration: .3s;
	}
	@keyframes menu_hide {
		0% {
			opacity: 1;
			top: 0;
		}
		100% {
			opacity: 0;
			top: -50px;
		}
  }

  .top-menu-block {
    background-color: #232323;
    position: fixed;
    height: 100vh;
    width: 270px;
    z-index: 10;
    transform: translateX(-270px);
    transition: all .3s;
    color: #ffffff;
    align-content: center;
    justify-content: center;
    ul {
      text-transform: uppercase;
      li {
        list-style-type: none;
        cursor: pointer;
        margin: 25px 0;
        i {
          margin-left: -25px;
        }
      }
    }
  }
  .line-mmenu {
    display: none;
    position: fixed;
    z-index: 5;
    background-color: rgba(35, 35, 35, .9);
    width: 100%;
    transition: all .3s;
    .btn-menu {
      cursor: pointer;
      span {
        color: #ffffff;
        padding: 6px 3px;
      }
      i {
        margin-bottom: 3px;
      }
    }
  }

  @media only  screen and (max-width: 650px) {
    .line-mmenu  {
      display: block;
    }
    .top-line {
      display: none;
    }
  }
</style>
