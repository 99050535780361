<template>
  <v-app>
    <div id="app">
      <component :is="layout">
        <router-view/>
      </component>
    </div>
  </v-app>
</template>

<script>
import HomeLayout from '@/layouts/HomeLayout.vue'
export default {
  components: {
    HomeLayout,
  },

  data: () => ({
    
  }),
  computed: {
    layout() {
      return this.$route.meta.layout
    }
  },
};
</script>

<style lang="scss" scoped>
  @import url('./assets/css/main.scss');
</style>
