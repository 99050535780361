<template>
  <v-carousel
    cycle
    class="home-carousel"
    :height="`${height}vh`"
    hide-delimiter-background
    show-arrows-on-hover
    interval=6000
  >
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      reverse-transition="fade-transition"
      transition="fade-transition"
    >
      <v-img
        :src="item.img"
        gradient="90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.0) 50%, rgba(255,255,255,1) 100%"
        height="100%"
        width="100%"
      ></v-img>
      <div class="txt" v-html="item.txt"></div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    data () {
      return {
        items: [
          {img: 'img/bg/adr_bg_c.jpg', txt: 'проводим курсы<br>ADR (перевозка опасных грузов)'},
          {img: 'img/bg/truck_bg_c.jpg', txt: 'обучаем<br>водителей - международников'},
          {img: 'img/bg/abc_bg_c.jpg', txt: '«ВитАвтоУниверсал»<br>все категории «A» «B» «C» «D» «E»'}
        ],
        height: '75'
      }
    },
  }
</script>

<style lang="scss" scoped>
  .txt {
    position: absolute;
    top: 23%;
    left: 7%;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: rgba(0,0,0,.5);
    padding: 10px;
    border-radius: 3px;
  }
</style>